.delete-wrapper p {
    color: var(--table-text-color);
    font-family: var(--Inter-SemiBold600);
    font-size: 16px;
    line-height: normal;
    letter-spacing: 0.36px;
    align-self: center;
    word-break: break-all;
    margin-right: 5px;
    text-align: center;
}

.delete-wrapper .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 30px;
}

.button-wrapper .but {
    font-size: 14px !important;
    padding: 6px 52px !important;
    font-family: var(--inter-Medium500) !important;
    border-radius: 6px !important;
}

.cancelButton {
    color: var(--active-green-text) !important;
    background-color: var(--active-green-back) !important;
    /* font-family: Inter; */
}

.deleteBtn {
    color: var(--inactive-red-text) !important;
    background-color: var(--inactive-red-back) !important;
}

.cancelButton:hover,
.deleteBtn:hover {
    opacity: 0.8;
}