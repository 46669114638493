.table-pagination-container {
    margin-top: 22px;
}

.pagination {
    margin-bottom: 0px !important;
}

.page-link.active,
.active>.page-link {
    color: #1D1D1D !important;
    border: none;
    background-color: none !important;
}

.active>.page-link {
    background: none !important;
    border: none !important
}

.page-item:last-child .page-link {
    padding: 0px;
    height: 100%;
    display: flex;
    align-items: center;
}

.page-item:first-child .page-link.previous {
    background-color: none !important;
    padding: 0px;
    height: 100%;
    display: flex;
    align-items: center;
}

.page-item:first-child .page-link.previous img {
    width: 9px;
    height: 9px;
    display: block !important;
    margin-right: 5px;
    /* transform: rotate(-180deg);  */
    margin-top: 2.2px;
}

.next-button img {
    width: 9px;
    height: 9px;
    margin-left: 5px !important;
    margin-top: 2.2px;
}

.page-link {
    color: var(--purple) !important;
    border: none !important;
    font-family: var(--Inter-SemiBold600);
    font-size: 16px;
    line-height: 18px;
    border-color: none !important;
    box-shadow: none !important;
}
