.modal-content {
    padding: 26px !important;
}

.modal-footer,
.modal-header {
    border: none !important;
}

.modal-header .modal-title {
    width: 95% !important;
    margin: auto !important;
    justify-content: center !important;
    align-items: center !important;
    display: flex !important;
    color: var(--light-black);
    text-align: center;
    font-family: var(--Inter-Bold700);
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.36px;
}

.modal-footer {
    justify-content: center !important;
}

.modal-footer button {
    border-radius: 7px !important;
    background: var(--field-background) !important;
    padding: 12px 52px !important;
    color: var(--light-black);
    text-align: center;
    font-family: var(--Inter-Bold700);
    font-size: 16px;
    text-transform: uppercase;
    border: none !important;
    width: 255px;
}

.modal-footer button:hover {
    background: var(--light_grey) !important;
    color: var(--light-black) !important;
}


.dropDown-box {
    width: 100%;
    height: 50px;
}

.dropDown-box button:nth-child(1) {
    width: 80% !important;
    background-color: var(--white) !important;
    border-top-left-radius: 16px !important;
    border-bottom-left-radius: 16px;
    border: 1px solid var(--light_grey) !important;
    color: var(--placeholder-grey) !important;
}

.dropDown-box button:nth-child(2) {
    background-color: var(--white) !important;
    border: 1px solid var(--light_grey) !important;
    color: #000 !important;
}

.dropdown-menu.show {
    width: 100% !important;
}

.modal-input-style {
    margin-bottom: 10px !important;
}

.modal-input-style .form-control {
    background-color: var(--field-background) !important;
    border: none !important;
    height: 50px !important;
    padding: 10px 20px !important;
    font-family: var(--Inter-SemiBold600);
    font-size: 16px !important;
    color: var(--table-text-color) !important;
}

.form-control:disabled {
    opacity: 0.7 !important;
}

.modal-input-style .form-control::placeholder {
    font-family: var(--inter-Regular);
    color: var(--placeholder-grey);
}

.item-wrapper .overlap-3 .span-wrapper {
    outline: none;
    background-color: transparent;
}


@media (min-width: 992px) {
    .modal-lg {
        max-width: 1150px !important;
    }
}

@media(max-width:768px) {
    .modal-content {
        padding: 15px !important;
    }

    .modal-input-style .form-control::placeholder,
    .modal-input-style .form-control {
        font-size: 16px !important;
    }
}

@media(max-width:480px) {
    .modal-content {
        padding: 0px !important;
    }
}