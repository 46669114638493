.forget-password-text {
    margin: 31px 0px !important;
    text-align: right;
    color: var(--purple) !important;
    font-family: var(--Inter-Medium);
    letter-spacing: -0.28px;
    font-size: 14px;
}


@media(max-width:400px) {
    .forget-password-text {
        margin: 20px 0px !important
    }
}