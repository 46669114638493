.loader-container-wrapper {
    top: 0;
    left: 0;
    background-color: var(--light_grey);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 1000;
}

.loader-container {
    position: fixed;
}

.loader-component-container {
    position: absolute;
}