.manage-video-table .headRow .divCell:nth-child(1),
.manage-video-table .divRow .divCell:nth-child(1) {
    width: 80px !important;
}

.manage-video-table .divRow .divCell:nth-child(2),
.manage-video-table .divRow .divCell:nth-child(5),
.manage-video-table .headRow .divCell:nth-child(2),
.manage-video-table .headRow .divCell:nth-child(5) {
    width: 110px !important;
    word-break: break-word;
}
.manage-video-table .divRow .divCell:nth-child(3){
    width: 240px !important;
}
.manage-video-table .divRow .divCell:nth-child(4){
    width: 240px !important;
}
.manage-video-table .divRow .divCell:nth-child(6),
.manage-video-table .divRow .divCell:nth-child(7),
.manage-video-table .headRow .divCell:nth-child(6),
.manage-video-table .headRow .divCell:nth-child(7) {
    min-width: none;
    max-width: none;
    width: 300px !important;
    flex-grow: 1;
}

.manage-video-table .headRow .divCell:nth-child(8),
.manage-video-table .divRow .divCell:nth-child(8) {
    min-width: none;
    max-width: none;
    min-width: 50px !important;
}


/* head realated styles******************* */
.manage-video-table .headRow .divCell:nth-child(8) {
    text-align: center;
}


.manage-video-table .headRow .divCell:nth-child(2) {
    margin-right: 5px;
}


/* Row  releated styles******************** */
.manage-video-table .divRow .divCell:nth-child(8),
.manage-video-table .divRow .divCell:nth-child(9) {
    margin-right: 0px !important;
    cursor: pointer;
}