#sidebar {
    width: 18.125rem;
    background-color: var(--white);
    border-right: solid 1px #F4F7FE;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

#sidebar>* {
    padding-left: 2.25rem;
}

#sidebar>div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #F4F7FE;
}

#sidebar nav {
    flex: 1;
    overflow: auto;
    padding-top: 2.562rem;
    width: 100%;
}


#sidebar nav a.active span {
    color: inherit;
}

i {
    color: #818181;
}

#sidebar nav .active i {
    color: inherit;
}

#sidebar ul {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
}

#sidebar li {
    margin: 0.25rem 0;
    width: 100%;
}

#sidebar nav a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    white-space: pre;
    border-radius: 8px;
    text-decoration: none;
    width: 100%;
    margin-bottom: 24px;
    padding: 10px 0px 10px 10px;
}

#sidebar nav a .active-bar {
    width: 4px;
    height: 36px;
    align-self: flex-end;
    margin-left: auto;
    border-radius: 25px;
}

#sidebar nav a .active-bar.active {
    background-color: var(--purple);
}

#sidebar nav a:hover {
    background: var(--field-background);
}

nav>ul>li {
}

nav>ul>li img {
    width: 18px;
    height: 18px;
    margin-right: 16px;
    mix-blend-mode: multiply;
}

nav .active {
    color: var(--light-black);
    font-family: var(--Inter-Bold700);
}

nav .inactive {
    color: var(--placeholder-grey);
    font-family: var(--inter-Medium500);
}
.main {
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
}

.scrollable-x::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit browsers */
}
#second-container-wrap .main {
    box-sizing: border-box;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: auto;
    scrollbar-color: #888 #f0f0f0;
    scrollbar-width: thin;
    white-space: nowrap;
    z-index: 9;
}

/* For Webkit browsers (Chrome, Edge, Safari) */
#second-container-wrap .main::-webkit-scrollbar {
    height: 8px; /* Adjust the scrollbar height */
}

#second-container-wrap .main::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

#second-container-wrap .main::-webkit-scrollbar-track {
    background: #f0f0f0;
}
