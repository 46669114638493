 .user-detail-header .header-button-wrapper {
     display: flex;
     flex-direction: row;
     margin-top: 5px;

 }

 .user-detail-header .header-button-wrapper>button:first-child {
     margin-right: 20px !important;
 }

 .user-detail-header .header-button-wrapper>button:first-child:hover {
     cursor: auto;
 }

 .user-detail-header .header-button-wrapper>button:nth-child(2):hover {
     opacity: 0.8 !important;
 }

 .user-info-subcontainer {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
 }



 /**********common***********************/
 .user-info-subcontainer .first-box,
 .user-info-subcontainer .second-box,
 .user-info-subcontainer .third-box {
     display: flex;
     flex: 1;
     flex-direction: column;
     margin-right: 25px;
 }

 .user-info-subcontainer .third-box {
     margin-right: 0px;
 }


 .user-info-subcontainer .third-box .client-coach-feedback-box,
 .user-info-subcontainer .third-box .client-history-box,
 .user-info-subcontainer .second-box .client-health-box,
 .user-info-subcontainer .second-box .client-report-box,
 .user-info-subcontainer .first-box .client-history-box,
 .user-info-subcontainer .first-box .client-intro-box {
     height: 435px;
     /* overflow: auto; */
     margin-bottom: 31px;
 }


 .common-card {
     border-radius: 15px;
     background: var(--white);
     box-shadow: 0px 18px 40px 0px rgba(112, 144, 176, 0.12);
     /* border: 1px solid red; */
     padding: 18px;
 }

 .common-card .text-wrapper-2 {
     color: var(--light-black);
     font-family: "DM Sans", Helvetica;
     font-size: 22px;
     font-weight: 700;
     line-height: 32px;
 }

 .text-wrapper-1 {
     color: var(--light-black);
     font-family: var(--Inter-Bold700);
     font-size: 18px;
     line-height: 28px;
     letter-spacing: -0.36px;
     margin-bottom: 15px !important;
 }

 .text {
     color: var(--text-color);
     font-family: var(--inter-Medium500);
     font-size: 16px;
     line-height: 26px;
     letter-spacing: -0.32px;
     white-space: pre-wrap;
     text-align: justify;
     word-break: break-word;
 }

 .question-title {
     color: var(--light-black);
     font-family: var(--inter-Medium500);
     font-size: 14px;
     line-height: 26px;
     letter-spacing: -0.28px;
 }

 .date {
     color: var(--text-color);
     text-align: center;
     font-family: var(--inter-Medium500);
     font-size: 14px;
     line-height: 26px;
     letter-spacing: -0.28px;
     margin-bottom: 30px;
 }


 /* width */
 .internal-scroll::-webkit-scrollbar {
     width: 3px;
 }

 /* Track */
 .internal-scroll::-webkit-scrollbar-track {
     background: #f1f1f1;
     margin: 10px 0px;
     border-radius: 15px;
 }

 /* Handle */
 .internal-scroll::-webkit-scrollbar-thumb {
     /* background: var(--purple); */
     background: var(--disbaled-color);
     border-radius: 15px;
 }

 /* Handle on hover */
 /* ::-webkit-scrollbar-thumb:hover {
    background: #555;
} */



 /*******************First box styles start************/
 .user-info-subcontainer .first-box {
     /* margin-right: 31px; */
 }

 .user-info-subcontainer .first-box .client-info-box {
     min-height: 750px;
 }


 /********************first box style end &&&& second box start****************/
 .user-info-subcontainer .second-box {
     /* margin-right: 31px; */
 }

 /******************* second box style end &&&&& third box start ************/

 .user-info-subcontainer .third-box .client-history-box .text-wrapper-2 {
     margin-bottom: 16px;
 }

 .user-info-subcontainer .third-box .client-history-box .date-container {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
 }


 .user-info-subcontainer .third-box .client-history-box .active-question-wrapper {
     margin-bottom: 10px;
 }

 .user-info-subcontainer .third-box .client-coach-feedback-box .feadback-header {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     margin-bottom: 15px;
     padding: 0px 18px;
 }

 .addfeedback-button {
     width: 91.654px !important;
     height: 35px !important;
     padding: 0px 8px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 12px !important;
     font-size: 18px !important;
     background: var(--field-background) !important;
     border: none;
 }

 .addfeedback-button:hover {
     cursor: pointer;
     opacity: 0.8;
 }

 .addfeedback-button img {
     width: 18px;
     height: 17px;
 }

 .client-coach-feedback-box .feedback-list-wrapper {
     display: flex;
     flex-direction: column;
     overflow: auto;
     flex: 1;
     padding: 0px 18px;

 }

 /********************third box end ****************************/



 .client-detail-wrapper {
     margin-top: 31px;
 }

 .client-detail-wrapper .item-wrapper {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     margin-bottom: 15px;
 }

 .client-detail-wrapper .item-wrapper .sub-item-wrapper {
     width: 100%;
     display: flex;
     flex-direction: row;
     align-items: center;
 }

 .client-detail-wrapper .item-wrapper .sub-item-wrapper .sub-item-wrapper-1 {
     display: flex;
     flex-direction: row;
     align-items: center;
     width: 60%;
     justify-content: space-between;
     /* margin-right: 20px; */
 }

 .client-detail-wrapper .item-wrapper .sub-item-wrapper .sub-item-wrapper-2 {
     display: flex;
     flex-direction: row;
     align-items: center;
     width: 40%;
     align-self: center;
 }

 .client-detail-wrapper .item-wrapper .sub-item-wrapper .key {
     /* width: 150px; */
     width: auto;
 }


 .client-detail-wrapper .item-wrapper .sub-item-wrapper .sub-item-wrapper-1 .overlap-3 {
     margin-left: 92px;
     margin-bottom: 0px;
     width: 50%;
 }

 .client-detail-wrapper .item-wrapper .sub-item-wrapper-2 .key {
     width: 50px !important;
     margin-left: 20px !important;
 }

 .client-detail-wrapper .item-wrapper .sub-item-wrapper .sub-item-wrapper-2 .overlap-3 {
     margin-bottom: 0px;
     width: 100% !important;
 }

 .item-wrapper.address-wrapper .key {
     align-self: flex-start;
 }

 .item-wrapper .address-overlap-3-wrapper {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     width: 70%;
     flex-wrap: wrap;

 }

 .item-wrapper .address-overlap-3-wrapper .overlap-3 {
     width: 48%;
     margin-bottom: 15px;
 }
 .tabHeader{
    display: flex !important;
    margin-bottom: 25px;
 }
 .tabHeader div{
    font-size: 18px;
    cursor: pointer;
    margin-left: 16px;
 }
.tabHeader .inactive{
    color: var(--placeholder-grey);
    font-family: var(--inter-Medium500);
}
.tabHeader .active{
    color: var(--light-black);
    font-family: var(--Inter-Bold700);
    border-bottom: 2px solid black;
}
 .item-wrapper .key {
     color: var(--text-color);
     font-family: var(--inter-Medium500);
     font-size: 14px;
     line-height: 20px;
     letter-spacing: -0.28px;
     width: 150px;
     padding-right: 10px !important;
 }

 .item-wrapper .overlap-3 {
     background-color: #f4f7fe;
     border-radius: 49px;
     width: 70%;
     min-height: 43px;
     border-radius: 49px;
     background: #F4F7FE;
     padding: 11px 43px;
 }

 .item-wrapper .overlap-3 .span-wrapper {
     color: #1E1E1E;
     font-family: var(--inter-Medium500);
     font-size: 14px;
     line-height: 20px;
     letter-spacing: -0.28px;
     word-break: break-all
 }

 /***********************user Response styles**********************/

 .user-response-container {
     /* margin-top: 31px; */
     box-shadow: 0px 0px 40px 0px rgba(112, 144, 176, 0.12);
     padding: 18px 0px;
 }

 .user-respose-wrapper {
     padding: 0px 18px;
     /* height: 200px; */
     overflow: auto;
 }

 .user-respose-wrapper .user-response-header .coach-header .left-box>p:nth-child(2) {
     margin-left: 40px !important;
     margin-top: 19px !important;
 }

 .user-respose-wrapper .user-response-header .coach-header .right-box {
     align-items: start !important;
 }

 /* .user-respose-wrapper .user-questionnair-history-wrapper {
     margin-left: 40px;
 } */

 .user-respose-wrapper .user-questionnair-history-wrapper .date-timelist-wrapper {
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     gap: 20px;
     flex-wrap: wrap;
     margin-bottom: 25px;
 }

 .user-respose-wrapper .user-questionnair-history-wrapper .date-timelist-wrapper .date-time-item {
     border-radius: 7px;
     background: var(--field-background);
     padding: 4px 21px !important;
     color: var(--light-black);
     text-align: center;
     font-family: var(--Inter-SemiBold600);
     font-size: 14px;
     line-height: 26px;
     letter-spacing: -0.28px;
     width: 201px;
     /* margin-bottom: 20px !important; */
 }

 .date-time-item-active {
     color: var(--purple) !important
 }
 .question-container-wrapper {
     margin-top: 10px !important;
 }

 .question-container-wrapper .question-title-wrapper {
     border-radius: 7px;
     background: var(--field-background);
     width: 100%;
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     padding: 4px 25px 4px 23px !important;
 }

 .question-container-wrapper .question-title-wrapper .left-arrow.upWard {
     transform: rotate(0deg) !important;
 }

 .question-container-wrapper .question-title-wrapper .left-arrow.downWard {
     transform: rotate(180deg) !important;
 }

 .question-container-wrapper .question-title-wrapper>img {
     width: 9px;
     height: 4px;
     align-self: center;
 }


 .question-deatil-wrapper {
     margin-top: 14px;
     margin-bottom: 20px;
 }

 .question-deatil-wrapper .response {
     margin: 15px 0px !important;
     color: var(--text-color);
     font-size: 14px !important;
     font-family: var(--inter-Medium500);
 }

 .question-deatil-wrapper .response>span {
     font-family: var(--Inter-Bold700);
 }

 .question-deatil-wrapper .client-note-wrapper {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     margin-bottom: 12px !important;
 }

 .question-deatil-wrapper .client-note-wrapper .text-wrapper-1 {
     margin-bottom: 0px !important;
 }

 .question-deatil-wrapper .text.extra-comment {
     color: var(--text-color1);
 }

 /* .date-timelist-wrapper p {
    cursor: pointer;
} */

 .date-timelist-wrapper p:hover {
     cursor: pointer;
     opacity: 0.8;
     /* background: var(--background-color1) !important; */
 }

 .client-detail-content-container {
     overflow: auto;
     /* height: calc(435px - 70px); */
     flex: 1;
     padding: 0px 18px;
 }

 .padding18 {
     padding: 0px 18px;
 }

 .marginBottom15 {
     margin-bottom: 15px;
 }




 /************@media quesris****************/



 @media(min-width:1920px) {

     .user-info-subcontainer .second-box,
     .user-info-subcontainer .third-box {
         flex-grow: 1;
     }
 }

 @media(max-width:1700px) {
     .user-info-subcontainer {
         flex-wrap: wrap;
     }

     .item-wrapper .overlap-3 {
         padding: 11px 20px;
     }

 }

 @media(max-width:1200px) {
     .user-info-subcontainer .second-box {
         margin-right: 0px;
     }

     .user-info-subcontainer .third-box {
         flex: none;
         width: 100%;
     }
 }

 @media(max-width:992px) {
     .user-info-subcontainer .first-box {
         margin-right: 0px;
     }

     .user-info-subcontainer .second-box,
     .user-info-subcontainer .third-box {
         flex: none;
         width: 100%;
     }

     .user-respose-wrapper .user-response-header .coach-header .left-box>p:nth-child(2),
     .user-respose-wrapper .user-questionnair-history-wrapper {
         margin-left: 0px !important;
     }

 }

 @media(max-width:575px) {

     .client-detail-wrapper .item-wrapper .sub-item-wrapper {
         flex-direction: column;
     }

     .client-detail-wrapper .item-wrapper .sub-item-wrapper .sub-item-wrapper-1 {
         width: 100%;
         justify-content: space-between;
         margin-bottom: 15px;
     }

     .client-detail-wrapper .item-wrapper .sub-item-wrapper .key {
         width: 150px;
     }

     .client-detail-wrapper .item-wrapper .sub-item-wrapper .sub-item-wrapper-1 .overlap-3 {
         margin-left: 0px;
         margin-bottom: 0px;
         width: 70%;
     }

     .client-detail-wrapper .item-wrapper .sub-item-wrapper .sub-item-wrapper-2 {
         width: 100%;
         justify-content: space-between;
     }

     .client-detail-wrapper .item-wrapper .sub-item-wrapper-2 .key {
         width: 150px !important;
         margin-left: 0px !important;
     }

     .client-detail-wrapper .item-wrapper .sub-item-wrapper .sub-item-wrapper-2 .overlap-3 {
         margin-bottom: 0px;
         margin-left: 0px;
         width: 70% !important;
     }

     .item-wrapper .address-overlap-3-wrapper .overlap-3 {
         width: 100%;
     }

     .user-respose-wrapper .user-response-header .coach-header .left-box>p:nth-child(2) {
         margin-left: 0px !important;
         margin-top: 7px !important;
     }

     .user-respose-wrapper .user-questionnair-history-wrapper {
         margin-left: 0px;
     }

     .user-respose-wrapper .user-response-header .header.coach-header .right-box {
         position: relative;
         margin-top: 0px !important;
         right: 0px !important;
     }
 }