.coach-header {
    width: 100%;
    background-color: var(--white);
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
}

.coach-header .left-box>p:nth-child(1) {
    color: var(--light-black);
    font-family: var(--Inter-Bold700);
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.44px;
}

.coach-header .left-box>p:nth-child(2) {
    color: var(--disbaled-color);
    font-family: var(--inter-Medium500);
    font-size: 16px;
    line-height: 21px;
}

.add-coach-button {
    min-width: 157px;
    height: 35px;
    display: flex;
    padding: 7px 23px 8px 23px;
    justify-content: center;
    align-items: center;
    /* flex-shrink: 0; */
    border-radius: 6px;
    background: #F4F7FE;
    border: none;
}

.add-coach-button:hover {
    /* background: var(--background-color1); */
    opacity: 0.8;
}

.buttonClassforQuestion {
    width: 164px;
    padding: 7px 18px 8px 17px;
}

.add-coach-button>img {
    width: 20px;
    height: 20px;
    margin-right: 4px;
}

.add-coach-button>p {
    color: var(--light-black);
    font-family: var(--Inter-Bold700);
    font-size: 10px;
    line-height: normal;
    text-transform: uppercase;
}