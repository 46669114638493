.text-area-style{
    padding:20px;
}
.addQuestionSelectInput{
    margin-bottom: 10px;
}
.addQuestionSelectInput .css-13cymwt-control{
    background: var(--field-background) !important;
    border: none !important;
    padding: 7px 10px !important;
    border-radius: 20px;
}
.addQuestionSelectPlaceHolderStyle {
    font-size: 16px !important;
    color: var(--placeholder-grey) !important;
    font-family: var(--inter-Regular);
}
.desc::placeholder>span{
    color: red !important;
}