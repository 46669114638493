.testimonial-wrapper {
    padding-bottom: 15px;
    border-bottom: 1px dotted var(--disbaled-color);
}

.testimonial-wrapper>div:first-child img {
    cursor: pointer;
    padding-left: 10px;
}

.t-head {
    border-bottom: 1px solid var(--placeholder-color3);
}

.t-head>p {
    
    font-size: 14px;
    color: var(--placeholder-grey);
}
.down-image-absolute{
    right: 6px;
    width: 16px;
    top: 18px;
    width: 14px;
    position: absolute;
    transform: rotate(180deg);
}
.t-head>p:nth-child(2) {
    text-align: right;
}
.subCategoryContainer{
    display: block;
}

 .subCategoryContainer{
    margin-bottom: 17px;
 }
.t-body p:nth-child(2){
    margin: 0px 62px !important;
}
.t-body>p {
    font-family: var(--Inter-SemiBold600);
    font-size: 14px;
    line-height: 24px;
    color: var(--dark-purple);
   
}

.custom-box-style {
    min-height: 903px;
    /* height: 'auto'; */
    /* max-height: 903 !important; */
    /* height: 903 !important; */
    flex-grow: 1;
    position: 'relative';
    margin-bottom: 0px !important
}

.video-wrapper {
    margin-bottom: 27px;
}

.video-wrapper .video-wrapper-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px;
    border-bottom: 1px solid var(--placeholder-color3);
}

.video-wrapper .video-wrapper-container .thumbnail-wrapper {
    margin-right: 20px;
}

.video-wrapper .video-wrapper-container .thumbnail-wrapper img {
    width: 80px;
    height: 80px;
}

.video-wrapper-container .description-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.video-wrapper-container .description-wrapper>p:first-child {
    font-family: var(--Inter-SemiBold600);
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-purple);
}

.video-wrapper-container .description-wrapper>p:nth-child(2)>span:nth-child(2) {
    font-family: var(--Inter-SemiBold600);
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-purple);
}


.admin-content-data-container {
    overflow: auto;
    height: calc(903px - 86px);
    padding: 0px 18px;
}

.health-cate-data-container {
    overflow: auto;
    flex: 1;
    padding: 0px 18px;
}



/* media queries******************/

@media(max-width:1700px) {
    .manage-app-wrapper .user-info-subcontainer .second-box {
        margin-right: 0px;
    }

    .manage-app-wrapper .user-info-subcontainer .third-box {
        flex: none;
        width: 100% !important;
        margin-top: 25px;
    }
}

@media(max-width:1250px) {
    .manage-app-wrapper .user-info-subcontainer .first-box {
        margin-right: 0px;
    }

    .manage-app-wrapper .user-info-subcontainer .second-box {
        flex: none;
        width: 100%;
        margin-top: 25px;
    }
}

@media(max-width:800px) {
    .manage-app-wrapper .user-info-subcontainer .first-box {
        width: 100%;
        flex: none;
        margin-right: 0px;
    }

    .admin-content-data-container {
        height: calc(435px - 86px);
    }

    .manage-app-wrapper .user-info-subcontainer .first-box .custom-box-style {
        min-height: unset;
    }

    .client-detail-wrapper .item-wrapper .overlap-3 {
        margin-bottom: 0px;
        margin-left: 0px;
        width: 70% !important;
        word-break: break-all;
    }
}

@media(max-width:678px) {
    .text-wrapper-1 {
        line-height: 18px;
    }
}

@media(max-width:450px) {


    /* .manage-app-wrapper .user-info-subcontainer .client-detail-wrapper .item-wrappper {
        display: flex !important;
        flex-direction: column !important;
    } */

    .client-detail-wrapper .item-wrapper {
        flex-direction: column !important;
        justify-content: flex-start !important;
        width: 100% !important;
        align-items: flex-start !important;
    }

    .client-detail-wrapper .item-wrapper>p:first-child {
        margin-bottom: 5px !important;
        text-align: left !important;
    }

    .client-detail-wrapper .item-wrapper .overlap-3 {
        width: 100% !important;
    }

    .t-body>p,
    .t-head>p {
        margin: 0px !important;
    }

    .t-body>p:nth-child(2) {
        margin-right: 30px !important;
    }
}