.question-table .divRow {
    margin: 17px 0px !important;
    padding: 5px 22px;
    /* margin-right: 2px !important; */
}

.question-table .headRow {
    padding: 13px 24px;
}

.question-table .divRow .divCell:nth-child(1),
.question-table .headRow .divCell:nth-child(1) {
    width: 110px;
}

.question-table .headRow .divCell:nth-child(3),
.question-table .headRow .divCell:nth-child(4) {
    width: 120px;
    margin-right: 15px;
    padding-left: 27px;

}

.question-table .divRow .divCell:nth-child(3),
.question-table .divRow .divCell:nth-child(4),
.question-table .headRow .divCell:nth-child(3),
.question-table .headRow .divCell:nth-child(4) {
    width: 120px;
    margin-right: 15px;
}

.question-table .divRow .divCell:nth-child(2),
.question-table .headRow .divCell:nth-child(2) {
    width: 850px;
    font-size: 14px !important;
}

.question-table .divRow .divCell:nth-child(2) {
    color: var(--table-text-color) !important;
}

.question-table .divRow .divCell:nth-child(6),
.question-table .divRow .divCell:nth-child(5),
.question-table .headRow .divCell:nth-child(6),
.question-table .headRow .divCell:nth-child(5) {
    min-width: 34px !important;
    max-width: 34px;
}

.question-table .divRow .divCell:nth-child(6),
.question-table .divRow .divCell:nth-child(5) {
    display: flex;
    justify-content: center;
}

.question-table .divRow .divCell:nth-child(6) {
    padding-right: 0px !important;
}

.question-table .divRow .divCell:nth-child(3) button:focus {
    cursor: none !important;
}

.question-table .divRow .divCell:nth-child(4),
.question-table .divRow .divCell:nth-child(5),
.question-table .divRow .divCell:nth-child(6) {
    cursor: pointer;
}

.question-table .divRow .divCell:nth-child(4).client-detail {
    position: relative;
}

.button-sm {
    background-color: #ECF1FE;
    padding: 10px !important;
    border-radius: 6px;
    color: var(--black);
    font-family: var(--Inter-SemiBold600);
    font-size: 10px;
    text-align: center;
    width: 98px;
    height: 32px;
}

.question-table .divRow .divCell:nth-child(3) .button-sm {

    border-radius: 6px;
    border: 1px solid #E9EFFF;
    background: var(--white);
}

.client-detail img {
    position: absolute;
    top: 12px;
    right: 41px;
}

/* Question detail styles */

.question-detail-cantainer {
    border-radius: 6px;
    background: #F4F7FE;
    min-height: 103px;
    padding: 15px 20px;
    margin-left: 20px;
    margin-right: 5px;
}

.question-detail-cantainer .option-container div p,
.question-detail-cantainer div:nth-child(1) p {
    color: #010101;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.2px;
    text-transform: uppercase;
}

.question-detail-cantainer div:nth-child(1) {
    display: flex;
    flex-direction: row;
}

.question-detail-cantainer div:nth-child(1) p {
    margin-right: 20px !important;
}

.question-detail-cantainer div:nth-child(1) p span {
    padding-left: 10px !important;
}

.question-detail-cantainer div:nth-child(1) p span {
    color: #8F9BBA;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.2px;
    text-transform: none;
}

.question-detail-cantainer .option-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

}

.question-detail-cantainer .option-container>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 50px;
    margin-top: 10px;
}

.question-detail-cantainer .option-container div .box {
    width: 12px;
    height: 12px;
    background: #D9D9D9;
    margin-right: 10px;
}

.question-detail-cantainer .option-container div p {
    border-radius: 6px;
    border: 1px solid var(--background-color1);
    background: var(--white);
    padding: 2px 14px !important;
    margin-right: 0px !important;
}
.disabled{
    cursor: not-allowed !important;
}
.icons-diabled svg {
    opacity: 0.7;
    cursor: default;
}