/* @import url('../../styles//GlobalStyles.css'); */

.footer-container {
    width: 100%;
    /* height: 67px; */
    background-color: var(--field-background);
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    padding-left: 40px;
    padding-top: 24px;
    padding-bottom: 17px;
    /* position: absolute;
    bottom: 0px; */
    position: fixed;
    bottom: 0;

    width: calc(100% - 290px);
    right: 0;
    z-index: 10;
}

.footer-container>p {
    color: var(--placeholder-grey);
    font-family: var(--inter-Medium500);
    font-size: 14px;
    /* line-height: 24px; */
    letter-spacing: -0.28px;
}