/* fontWeight with 400 */
@font-face {
  font-family: "Inter-Regular";
  src: url("./assets//fonts//Inter-Regular.ttf") format("truetype");
}

/* fontWeight with 500 */
@font-face {
  font-family: "Inter-Medium";
  src: url("./assets//fonts/Inter-Medium.ttf") format("truetype");
}

/* fontWeight with 600 */
@font-face {
  font-family: "Inter-SemiBold";
  src: url("./assets//fonts/Inter-SemiBold.ttf") format("truetype");
}

/* fontWeight with 800 */
@font-face {
  font-family: "Inter-Bold";
  src: url("./assets/fonts/Inter-Black.ttf") format("truetype");
}

/* body {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
} */

.App {
  height: 100vh !important;
}
