.addQuestion-modal-container {}

.addQuestion-modal-container form>div {
    margin-bottom: 13px;
}

.addQuestion-modal-container form .form-control {
    background-color: var(--field-background) !important;
    border: none !important;
    height: 50px !important;
    padding: 10px 20px !important;
    font-family: var(--Inter-SemiBold600);
    font-size: 16px !important;
    color: var(--table-text-color) !important;
}

.addQuestion-modal-container form .form-control::placeholder {
    font-family: var(--inter-Regular);
    /* color: var(--placeholder-grey); */
}

.addQuestion-modal-container form>div:nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.addQuestion-modal-container form>div:nth-child(1)>div {
    width: 49%;
}

.addQuestion-modal-container form>div:nth-child(1) .css-b62m3t-container {
    /* width: 49%; */
    /* height: 68px; */
    border-radius: 15px !important;
}

.addQuestion-modal-container form>div:nth-child(1) .css-b62m3t-container .css-1yhe670-control {
    border-radius: 15px !important;
    background: var(--field-background) !important;
    border: 1px solid var(--field-background) !important;
    height: 50px !important;
    padding: 0px 12px;
}

.addQuestion-modal-container form>div:nth-child(1) .css-b62m3t-container .css-1nkf596-control {
    border-radius: 15px;
    background: var(--field-background) !important;
    /* border: none !important; */
    height: 50px !important;
    padding: 0px 12px;
}

.addQuestion-modal-container form>div:nth-child(1) .css-b62m3t-container .css-1nkf596-control svg {
    width: 20px !important;
    height: 20px !important;
}

.addQuestion-modal-container form>div:nth-child(1) .css-b62m3t-container .css-1yhe670-control .css-tj5bde-Svg {
    fill: var(--black) !important;
    width: 20px !important;
    height: 20px !important;
}

.css-lnmdiq5-menu {
    height: 500px !important;
}

.options-container {
    display: flex;
    flex-direction: 'row';
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1.33%
}

.options-container .options {
    width: 24%;
    position: relative;
}

.options-container input {
    margin-bottom: 13px;
    border: 1px solid var(--field-background) !important;
}

.options-container .options svg {
    position: absolute;
    top: 3px;
    right: 4px;
    padding: 2px;
    border: .5px solid var(--inactive-red-text);
    border-radius: 50%;
}

.options-container .options svg:hover {
    cursor: pointer;
}

input.form-control:focus {
    border: 1px solid var(--purple) !important;
}

.addQuestion-modal-container .placeHolderStyle {
    font-size: 16px !important;
    line-height: 16px !important;
}


.options-container .deleteButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px !important;
    padding: 10px 40px !important;
    border-radius: 15px !important;
    vertical-align: 'center';
    cursor: pointer;
    font-size: 16px !important;
}



/* /Styles for diabled  */

.addQuestion-modal-container form>div:nth-child(1) .css-3iigni-container {
    border-radius: 15px !important;
}

.addQuestion-modal-container form>div:nth-child(1) .css-3iigni-container .css-n3rqp9-control {
    border-radius: 15px !important;
    background: var(--field-background) !important;
    border: 1px solid var(--field-background) !important;
    height: 50px !important;
    padding: 0px 12px;
}


.addQuestion-modal-container form>div:nth-child(1) .css-3iigni-container .css-n3rqp9-control .css-tj5bde-Svg {
    fill: var(--black) !important;
    width: 20px !important;
    height: 20px !important;
}


@media(max-width:768px) {
    .addQuestion-modal-container form>div:nth-child(1) {
        flex-direction: column !important;
    }

    .addQuestion-modal-container form>div:nth-child(1)>div:first-child {
        margin-bottom: 13px;
    }

    .addQuestion-modal-container form>div:nth-child(1)>div {
        width: 100%;
    }

    .options-container {
        gap: 2%;
    }

    .options-container .options {
        width: 49%;
        position: relative;
    }
}

@media(max-width:400px) {
    .addQuestion-modal-container form>div:nth-child(1)>div:first-child {
        margin-bottom: 13px;
    }

    .addQuestion-modal-container form>div:nth-child(1)>div {
        width: 100%;
    }

    .options-container {
        gap: none !important;
    }

    .options-container .options {
        width: 100%;
        position: relative;
    }
}