.assign-coach-modal .modal-content {
    /* max-height: 500px; */
    min-height: 500px;
}

.assignQuestion-modal-container {
    width: 100%;
    /* max-height: 486px;
    overflow: auto; */
}


.assignQuestion-modal-container form>div:nth-child(1)>div {
    width: 100%;
}

.selected-question-wrapper {
    max-height: 300px;
    overflow: auto;
    /* background: red; */
    padding-right: 10px;
}


.render-selected-question-wrapper {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    border-radius: 15px !important;
    background: var(--field-background) !important;
    border: 1px solid var(--field-background) !important;
    min-height: 50px !important;
    padding: 5px 24px 5px 15px;
    margin-bottom: 15px;
    position: relative;
}


.assignQuestion-modal-container .css-3iigni-container>div:nth-child(2)>div:first-child,
.assignQuestion-modal-container .css-1yhe670-control>div:nth-child(2)>div:first-child {
    display: none !important;
}

.assignQuestion-modal-container .css-3iigni-container>div:nth-child(2)>div:first-child,
.assignQuestion-modal-container .css-1nkf596-control>div:nth-child(2)>div:first-child {
    display: none !important;
}

/* .css-3iigni-container */


.selected-Item {
    width: 14px;
    height: 14px;
    margin-right: 20px;
    border-radius: 50%;
    background-color: var(--inactive-red-text);
    cursor: pointer;
}

.unselected-item {
    width: 14px;
    height: 14px;
    margin-right: 20px;
    border-radius: 50%;
    border: 1px solid var(--active-green-text);
    cursor: pointer;
}

.css-n3rqp9-control {}

.question-cross-icon {
    position: absolute;
    right: 16px;
    top: 13px;
}



/*********************media queries*****************/