/* fontWeight with 400 */
@font-face {
  font-family: "Inter-Regular";
  src: url("../../src/assets/fonts/Inter-Regular.ttf") format("truetype");
}

/* fontWeight with 500 */
@font-face {
  font-family: "Inter-Medium";
  src: url("../../src/assets/fonts/Inter-Medium.ttf") format("truetype");
}

/* fontWeight with 600 */
@font-face {
  font-family: "Inter-SemiBold";
  src: url("../../src/assets/fonts/Inter-SemiBold.ttf") format("truetype");
}

/* fontWeight with 700 */
@font-face {
  font-family: "Inter-Bold";
  src: url("../../src/assets/fonts/Inter-Bold.ttf") format("truetype");
}

:root {
  --white: #ffffff;
  --black: #000000;
  --red: "red";
  --required-red: #ff1818;
  --light-black: #1e1e1e;
  --purple: #7525e8;
  --dark-purple: #2b3674;
  --dark-grey: #707eae;
  --light_grey: #e0e5f2;
  --text-color: #838383;
  --text-color1: #313131;
  --subtitle-color: #323232;

  --active-green-back: #f4fff3;
  --active-green-text: #5f8d4e;
  --inactive-red-back: #ffe2e5;
  --inactive-red-text: #f64e60;

  --field-background: #f4f7fe;
  --disbaled-color: #b5b5c3;
  --table-text-color: #464e5f;
  --icons-color: #575757;
  --background-color1: #e9efff;

  --placeholder-grey: #a3aed0;
  --placehoder-color1: #d9d9d9;
  --placeholder-color2: #d7d7d7;
  --placeholder-color3: #e9edf7;

  --inter-Regular: Inter-Regular;
  --inter-Medium500: Inter-Medium;
  --Inter-SemiBold600: Inter-SemiBold;
  --Inter-Bold700: Inter-Bold;
}

html,
body {
  height: 100%;
  background-color: #f0f0f0;
  margin: 0px;
  padding: 0px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
b,
strong,
body {
  margin: 0px !important;
  padding: 0px !important;
}

p a {
  color: var(--purple);
  text-decoration: none;
}

.linkStyle {
  text-decoration: none;
  word-break: break-all;
}

a:hover,
p a:hover {
  color: rgba(117, 37, 232, 0.8);
}

a {
  font-family: var(--Inter-SemiBold600);
  color: var(--purple);
  text-decoration: none;
  cursor: pointer;
}

.reset-cursor {
  cursor: default !important;
}

.left-arrow {
  width: 9px;
  height: 9px;
}

.diabled {
  opacity: 0.7;
}

.btn-close:focus {
  box-shadow: none !important;
}

.content-header {
  width: 100%;
  padding: 0px 18px;
}

.text-style-wrap {
  word-break: break-all;
}

.nodata-found-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: var(--inter-Medium500);
  font-size: 15px;
  color: var(--disbaled-color);
  height: 500px;
}

.required-Astric {
  color: var(--required-red);
  font-family: var(--inter-Medium500);
  font-size: 14px;
}

/* color classes
 */
.background-purple {
  background-color: var(--purple) !important;
}

/* /dashboard main content wrapper styles */
.dashboard-main-data-wrap {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  overflow-x: hidden;
  position: relative;
  margin: auto;
}

.dashboard-main-data-wrap .row {
  height: 100%;
}

.main {
  overflow-x: scroll;
  overflow-y: hidden;
}

@media (max-width:1700px) {
  .main {
    overflow-y: scroll;
  }
}

.main {
  border-radius: 20px;
  background-color: var(--white);
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 21.63px 22px 40px 22px;
  margin-bottom: 63px;
  /* min-height: calc(100% - 195px); */
  height:calc(100vh - 221px);
}

/* Toast classes on the top */

.text-danger {
  color: "red";
}

.text-success {
  color: var(--active-green-text) !important;
}

.toast-cont {
  margin-top: 15px;
  background: var(--field-background);
  border-radius: 15px;
  width: 100%;
  padding: 10px;
  padding-left: 20px;
  font-family: var(--inter-Medium500);
}

.toast-cont>p {
  font-size: 14px;
  font-family: var(--inter-Medium500);
}

.error-message-style-container {
  padding-top: 5px !important;
}

.passShow-eye,
.confirmPassShow-eye {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 37px;
}

/* form related css */
.wrapper {
  height: 100vh;
}

.auth-page-wrapper {
  width: 100%;
  height: 100%;
  background-color: "#fff" !important;
}

.auth-page-wrapper .row {
  height: 100%;
  justify-content: center;
  align-items: center;
}

.auth-page-wrapper .auth-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.auth-page-wrapper .auth-container .auth-col {
  width: 50%;
}

.form-field-container {
  width: 70% !important;
  margin: auto;
  max-width: 410px;
}

.auth-page-wrapper .auth-container>div:nth-child(2) {
  height: 100%;
  background-image: linear-gradient(to bottom, #7525e833, #22a91633);
  border-bottom-left-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-page-wrapper .auth-container>div:nth-child(2) .logo-container {
  width: 360px;
  max-width: 360px;
  height: auto;
  margin: 50px;
}

.auth-page-wrapper .auth-container>div:nth-child(2) .logo-container>img {
  height: 100%;
  width: 100%;
}

/* form related styles global */

.form-control {
  border-radius: 16px !important;
  border: 1px solid var(--light_grey) !important;
  height: 50px !important;
  font-size: 14px !important;
  font-family: var(--inter-Regular);
  color: var(--black) !important;
  transition: none !important;
  box-shadow: none !important;
}

.input-label-style {
  font-family: var(--inter-Medium500);
  font-size: 14px !important;
  color: var(--dark-purple) !important;
  font-style: normal;
  letter-spacing: -0.28px;
  margin-bottom: 13px;
}

/* placeholder color or input*/

.text-area-style {
  padding: 20px;
  color: var(--table-text-color) !important;
}

input#exampleInputPassword::placeholder,
input#exampleInputEmail1::placeholder,
input#exampleInputName::placeholder {
  color: var(--placeholder-grey);
  font-size: 16px !important;
}

textarea::placeholder {
  color: var(--placeholder-grey) !important;
  font-size: 16px !important;
  font-family: var(--inter-Regular);
}

input.placeholder-style::placeholder {
  color: var(--placeholder-grey) !important;
  font-size: 16px !important;
}

input.form-control:focus {
  border: 1px solid var(--purple) !important;
}

.form-control-icon-space {
  padding-right: 48px !important;
}

.forminut-passeye,
.form-group.forminut-confirmPassShow {
  position: relative;
  margin-bottom: 24px;
}

.auth-page-title {
  color: var(--purple);
  font-family: var(--Inter-Bold700);
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -0.72px;
}

.auth-page-subtitle {
  color: var(--placeholder-grey);
  font-family: var(--inter-Regular);
  font-size: 16px;
  letter-spacing: -0.32px;
}

.fields-container {
  margin-top: 41px;
}

/* simple Button style */

.simplebuttonStyle {
  display: flex !important;
  height: 54px !important;
  padding: 10px 8px !important;
  justify-content: center;
  align-items: center;
  border-radius: 16px !important;
  border: none !important;
  font-family: var(--Inter-Bold700) !important;
  letter-spacing: -0.28px;
  font-size: 14px !important;
}

/* dashboar layout container styles */

.main-container {
  width: 100%;
  height: 100vh;
}

#second-container-wrap {
  width: 100%;
  height: 100vh;
  background-color: var(--field-background);
  position: relative;
  overflow: scroll;
  overflow-x: hidden;
  padding: 14px;
}

.main-container .main-sub-container {
  display: flex;
  flex-direction: row;
  margin: 0px;
  position: relative;
}

.hamburger-menu {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 45px;
  background-color: #fff;
  z-index: 12;
  text-align: left;
  border-bottom: 1px solid var(--field-background);
}

.hamburger-menu svg {
  height: 25px;
  width: 25px;
  margin: 10px 0px 5px 14px;
}

.showSidebar {
  display: block !important;
}

/* .header {
    display: flex;
    flex-direction: row;
    padding: 0px 18px;
    padding-top: 45px;
    padding-bottom: 19px;
    justify-content: space-between;
    background-color: var(--field-background);
    z-index: 10;
    top: 0;
    right: 0;
    width: 100%;
}


.profile-toast>p,
.header .left-box>p:nth-child(1) {
    color: var(--dark-grey);
    font-family: var(--inter-Medium500);
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 12px;
}

.header .left-box>p:nth-child(2) {
    color: var(--light-black);
    font-family: var(--Inter-Bold700);
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -0.68px;
}

.header .right-box {
    display: flex;
    flex-direction: row;
    align-items: center
}

.header .right-box>div {
    width: 41px;
    height: 41px;
    background-color: var(--white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header .right-box>div:nth-child(2) {
    margin-left: 12px;
    position: relative;
}

.profile-toast {
    position: absolute;
    top: 46px;
    right: 0px;
    padding: 15px;
    border-radius: 10px;
    background-color: var(--white);
    width: 150px;
    z-index: 11;
    border: 1px solid var(--field-background);
} */

.status-toast {
  top: 20px;
  width: 90px;
  left: 0px;
}

.profile-toast>p:hover {
  color: var(--light-black);
  cursor: pointer;
}

/* custom table styles */
.table-wrapper-container {}

.table-container {
  padding-right: 2px !important;
}

.divTable {
  display: table;
  width: auto;
  border-spacing: 5px;
  width: 100%;
}

.divTable .headRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  background: var(--field-background);
  padding: 14px 24px;
  margin-right: 2px !important;
}

.divTable .headRow .divCell {
  color: var(--dark-grey);
  font-family: var(--Inter-SemiBold600);
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.36px;
}

.divTable .headRow .divCell:nth-child(1),
.divTable .headRow .divCell:nth-child(3) {
  width: 200px;
}

.divTable .headRow .divCell:nth-child(2),
.divTable .headRow .divCell:nth-child(4) {
  width: 180px;
}

.css-1xc3v61-indicatorContainer {
  display: none !important;
}

.css-15lsz6c-indicatorContainer {
  display: none !important;
}

.divTable .headRow .divCell:nth-child(5) {
  width: 160px;
  position: relative;
  cursor: pointer;
}

.divRow {
  display: flex;
  width: 100%;
  width: auto;
  padding: 9px 22px;
  margin: 30px 0px;
  justify-content: space-between;
}

.divRow:hover {
  background: var(--field-background);
}

.divCell {
  min-width: 100px;
}

.divRow .divCell {
  color: var(--table-text-color);
  font-family: var(--Inter-SemiBold600);
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.36px;
  align-self: center;
  word-break: break-all;
  margin-right: 5px;
}

.divRow .divCell .delete {
  width: 14px;
  height: 16px;
  cursor: pointer;
}

.divCell .divCell .delete:hover {
  cursor: pointer;
  background-color: var(--disbaled-color);
}

.divRow .divCell:nth-child(1) {
  width: 200px;
}

.divTable .headRow .divCell:nth-child(3),
.divRow .divCell:nth-child(3) {
  width: 240px;
}

.divRow .divCell:nth-child(2),
.divRow .divCell:nth-child(4) {
  width: 180px;
}

.divRow .divCell:nth-child(5) {
  width: 160px;
}

.divRow .divCell:nth-child(2) {
  color: var(--disbaled-color);
  font-size: 13px;
}

.divRow .divCell:nth-child(6) {
  display: flex;

  padding-right: 10px;
}

.divTable .headRow .divCell:nth-child(8),
.divRow .divCell:nth-child(8) {
  min-width: 50px !important;
  text-align: center;
}

.divRow .divCell:nth-child(5) button {
  width: 89px !important;
}

.divRow .divCell:nth-child(6) button,
.divRow .divCell:nth-child(8) button {
  width: 98px !important;
}

.upWard {
  margin-left: 5px;
  transform: rotate(-90deg);
}

.downWard {
  margin-left: 5px;
  transform: rotate(90deg);
}

.buttonAcive {
  color: var(--active-green-text) !important;
  background-color: var(--active-green-back) !important;
  /* font-family: Inter; */
  font-size: 11px !important;
  height: 26px !important;
  padding: 6px 12px !important;
  font-family: va(--inter-Medium500) !important;
  border-radius: 6px !important;
}

.buttonInactive {
  color: var(--inactive-red-text) !important;
  background-color: var(--inactive-red-back) !important;
  font-size: 11px !important;
  height: 26px !important;
  padding: 6px 12px !important;
  font-family: va(--inter-Medium500) !important;
  border-radius: 6px !important;
}

.inActiveButton {
  color: var(--placeholder-grey) !important;
  background-color: var(--field-background) !important;
  font-size: 11px !important;
  height: 26px !important;
  padding: 6px 12px !important;
  font-family: va(--inter-Medium500) !important;
  border-radius: 6px !important;
  padding-left: 0px !important;
}

.outlineButton {
  color: var(--black) !important;
  background-color: var(--white) !important;
  border: 1px solid var(--background-color1) !important;
  font-family: var(--Inter-SemiBold600) !important;
  font-size: 10px !important;
  height: 26px !important;
  padding: 10px 10px !important;
  border-radius: 6px !important;
  text-transform: uppercase !important;
  height: 32px !important;
}

.deleteButton {
  color: var(--black) !important;
  background-color: var(--field-background) !important;
  border: 1px solid var(--background-color1) !important;
  font-family: var(--Inter-SemiBold600) !important;
  font-size: 10px !important;
  height: 26px !important;
  padding: 10px 10px !important;
  border-radius: 6px !important;
  text-transform: uppercase !important;
  height: 32px !important;
}

.outlineButton:hover {
  background-color: var(--field-background) !important;
}

.deleteButton:hover {
  background-color: var(--white) !important;
}

/* client table styling */

.divTable.client-table .divCell {
  min-width: 50px !important;
}

.divTable.client-table .headRow {
  justify-content: space-between;
}

.divTable.client-table .headRow .divCell {
  margin-right: 5px;
}

.divTable.client-table .divRow .divCell:nth-child(1),
.divTable.client-table .headRow .divCell:nth-child(1) {
  width: 160px;
  flex-grow: 0.2;
}

.divTable.client-table .divRow .divCell:nth-child(3),
.divTable.client-table .headRow .divCell:nth-child(3) {
  width: 230px;
  padding-right: 15px !important;
  flex-grow: 0.2;
}

.divTable.client-table .divRow .divCell:nth-child(2),
.divTable.client-table .headRow .divCell:nth-child(2) {
  width: 100px;
}

.divTable.client-table .divRow .divCell:nth-child(4),
.divTable.client-table .headRow .divCell:nth-child(4) {
  width: 120px;
}

.divTable.client-table .divRow .divCell:nth-child(5),
.divTable.client-table .headRow .divCell:nth-child(5) {
  width: 100px;
}

.divTable.client-table .divRow .divCell:nth-child(7),
.divTable.client-table .headRow .divCell:nth-child(7) {
  width: 200px;
}

.divTable.client-table .divRow .divCell:nth-child(6),
.divTable.client-table .divRow .divCell:nth-child(7),
.divTable.client-table .divRow .divCell:nth-child(8) {
  font-size: 10px !important;
  padding-right: 0px !important;
}

.divTable.client-table .divRow .divCell:nth-child(6),
.divTable.client-table .divRow .divCell:nth-child(8),
.divTable.client-table .headRow .divCell:nth-child(6),
.divTable.client-table .headRow .divCell:nth-child(8) {
  width: 194px;
  text-align: left;
}

.divTable.client-table .headRow .divCell:nth-child(9),
.divTable.client-table .divRow .divCell:nth-child(9) {
  width: 100px;
  text-align: left;
}

.request-status-Container {
  position: relative;
  display: flex;
  flex-direction: row;
}

.divTable.client-table .divRow .divCell:nth-child(10) {
  margin-right: 0px;
  text-align: center;
  padding: 8px 0px;
}

.divTable.client-table .divRow .divCell:nth-child(10):hover {
  cursor: pointer;
}

.divTable.client-table .divRow .divCell:nth-child(6):focus {
  cursor: pointer;
}

.divTable.client-table .divRow {
  padding: 8.5px 22px !important;
}

.divTable.client-table .divRow:hover {
  cursor: pointer;
}

.css-3iigni-container,
.divCell .css-b62m3t-container {
  width: 100%;
}

.divTable.client-table .divRow .divCell .css-3iigni-container,
.divTable.client-table .divRow .divCell .css-b62m3t-container {
  /* height: 32px !important; */
}

/* .divTable.client-table .divRow .divCell  div {
    height: 32px !important;
} */

.css-n3rqp9-control,
.css-1yhe670-control {
  min-height: 30px !important;
}

.css-tj5bde-Svg {
  fill: var(--purple) !important;
  height: 15px !important;
  width: 15px !important;
}

.divCell .css-b62m3t-container .css-9e39r0-control {
  border: none !important;
  border-radius: 6px !important;
  cursor: pointer;
}

.css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}

.placeHolderStyle {
  font-size: 12px !important;
  color: var(--placeholder-grey) !important;
  font-family: var(--inter-Regular);
}

.request-status-dropdown-container {
  position: relative;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.request-status-dropdown-container img {
  position: absolute;
  top: 8px;
  right: 9px;
}

.request-status-toast {
  top: 28px !important;
  width: 100px !important;
}

/* page not found styles**** */

.dashboardContent.error-template .row {
  background: var(--light-brown) !important;
  height: 100vh;
  padding: 0px !important;
  margin: 0px !important;
  width: 100%;
  justify-content: center;
}

.region.region-content {
  height: auto;
  text-align: center;
  box-shadow: 5px 7px 20px 0px #cccc;
  margin: 4% 0px;
  background: #ffffff54;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboardContent.error-template img {
  object-fit: scale-down;
  width: 40%;
}

.dashboardContent.error-template .row .title {
  color: var(--light-black);
  font-family: var(--Inter-Bold700);
  font-size: 34px;
  line-height: 42px;
  letter-spacing: -0.68px;
}

.content {
  color: var(--table-text-color);
  font-family: var(--Inter-SemiBold600);
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.36px;
  align-self: center;
  word-break: break-all;
  margin-right: 5px;
  margin-top: 10px !important;
}

.dashboardContent.error-template .content.continue-program-text {
  color: var(--purple);
  text-decoration: none;
  text-decoration: none;
  word-break: break-all;
  cursor: pointer;
}

.dashboardContent.error-template .content.continue-program-text:hover {
  opacity: 0.8;
}

.divTable.manage-video-table .divCell {
  word-break: break-word;
  white-space: wrap;
}

.divTable.manage-video-table .divRow .divCell {
  align-self: start;
}

.table-wrapper-container {
  overflow-x: scroll;
  max-width: 100%;
  white-space: nowrap;
  z-index: 9;
  box-sizing: content-box;
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}

.table-wrapper-container::-webkit-scrollbar,
.table-wrapper-container::-moz-scrollbar {
  height: 8px;
  border-radius: 10px;
  background-color: #f1f1f1;
}

.table-wrapper-container::-webkit-scrollbar-thumb,
.table-wrapper-container::-moz-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.table-wrapper-container::-webkit-scrollbar-thumb:hover,
.table-wrapper-container::-moz-scrollbar-thumb:hover {
  background: #555;
}

/* MEDIA QUERIES */
@media (max-width: 1700px) {}

@media (max-width: 768px) {
  .main-container .main-sub-container .first-container-wrap {
    display: none;
    position: absolute;
    top: 45px;
    left: 0px;
    width: 18.125rem;
    z-index: 1001;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
  }

  .main-container .main-sub-container .first-container-wrap #sidebar {
    height: calc(100vh - 45px) !important;
  }

  .hamburger-menu {
    display: block;
    z-index: 1001;
  }

  .main {
    padding: 22px;
  }

  .footer-container {
    width: 100% !important;
    padding-right: 14px !important;
  }

  .auth-page-title,
  .header .left-box>p:nth-child(2) {
    font-size: 29px;
    line-height: 29px;
  }

  .form-field-container {
    width: 80% !important;
    max-width: none;
    padding-top: 40px !important;
  }

  .auth-page-wrapper .auth-container {
    display: flex;
    flex-direction: column-reverse !important;
    justify-content: flex-end;
  }

  .auth-page-wrapper .auth-container .auth-col {
    width: 100%;
  }

  .auth-page-wrapper .auth-container .auth-col-2 {
    width: 100%;
    padding-bottom: 80px;
  }

  .auth-page-wrapper .auth-container>div:nth-child(2) {
    height: auto !important;
    border-bottom-left-radius: 50px;
  }

  .auth-page-wrapper .auth-container>div:nth-child(2) .logo-container {
    width: 250px;
    max-width: 250px;
    height: auto;
    margin: 35px;
  }

  .auth-container .auth-col .form-field-container {
    width: 100% !important;
    padding: 20px;
  }

  .wrapper {
    height: auto !important;
  }

  .App {
    height: 100%;
    background: white;
  }
}

@media (max-width: 440px) {
  .header {
    flex-direction: column !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .header .left-box {
    margin-bottom: 19px;
  }

  .auth-page-title,
  .header .left-box>p:nth-child(2) {
    font-size: 25px;
    line-height: 25px;
  }

  .main {
    padding: 16px;
  }

  .coach-header .left-box>p:nth-child(1) {
    font-size: 19px !important;
    line-height: 19px !important;
  }

  .header .right-box {
    justify-content: flex-end;
  }

  .paginatino-button {
    display: none;
  }

  .modal-footer button,
  .options-container .deleteButton {
    width: 100% !important;
  }

  .form-field-container {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .auth-container .auth-col .form-field-container {
    width: 100% !important;
    padding: 20px 15px;
    padding-bottom: 0px;
  }

  .auth-page-wrapper .auth-container>div:nth-child(2) .logo-container {
    width: 200px;
    max-width: 200px;
    height: auto;
    margin: 50px;
  }

  #second-container-wrap .header .left-box {
    margin-bottom: 0px !important;
  }

  #second-container-wrap .header .right-box {
    position: fixed;
    top: 3px;
    right: 12px;
    z-index: 22;
  }

  #second-container-wrap .header.coach-header .right-box {
    position: relative;
    margin-top: 15px;
    right: 0px !important;
  }

  #sidebar {
    width: 16rem !important;
  }

  #sidebar>* {
    padding-left: 1rem !important;
  }

  .dashboardContent.error-template .col-lg-3 {
    display: none;
  }

  .dashboardContent.error-template img {
    width: 60%;
  }

  .dashboardContent.error-template .col-lg-6.region.region-content {
    margin: 25px;
    /* height: auto !important; */
  }
}

/* modal width media Queries */

@media (min-width: 768px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 700px;
  }
}