.header {
    display: flex;
    flex-direction: row;
    padding: 0px 18px;
    padding-top: 45px;
    padding-bottom: 19px;
    justify-content: space-between;
    background-color: var(--field-background);
    z-index: 10;
    top: 0;
    right: 0;
    width: 100%;
}

.profile-toast {
    position: absolute;
    top: 46px;
    right: 0px;
    padding: 15px;
    border-radius: 10px;
    background-color: var(--white);
    width: 150px;
    z-index: 99999;
    border: 1px solid var(--field-background);
}

.profile-toast>p,
.header .left-box>p:nth-child(1) {
    color: var(--dark-grey);
    font-family: var(--inter-Medium500);
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 12px;
    word-break: break-all;
}

.header .left-box>p:nth-child(2) {
    color: var(--light-black);
    font-family: var(--Inter-Bold700);
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -0.68px;
}

.header .right-box {
    display: flex;
    flex-direction: row;
    align-items: center
}

.header .right-box>div {
    width: 41px;
    height: 41px;
    background-color: var(--white);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}


.header .right-box>div:nth-child(2) {
    margin-left: 12px;
    position: relative;
}


.icon-container {
    cursor: pointer;
}

.icon-container {
    position: relative !important;
}

.header-icon-style {
    color: '#7525E8';
    width: '24px';
    height: '24px';
}

.header .notifi-icon .notifi-count {
    padding-left: 5px !important;
    padding-right: 5px !important;
}