.notification-wrapper .user-info-subcontainer {
    display: flex !important;
    flex-direction: column !important;
}

.notification-wrapper-box {
    width: 100% !important;
    margin-bottom: 10px;
    padding: 0px !important;
    transition: all ease-in-out 5;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.message-wrapper {
    width: 100%;
    padding: 15px 25px !important;
}

.notification-wrapper-box p {
    font-size: 14px !important;
    margin-bottom: 13px;
    margin-right: 20px;
    width: calc(100% - 60px);
    color: var(--dark-purple);
    font-family: var(--inter-Medium500);
    line-height: 26px;
    letter-spacing: -0.32px;
    white-space: pre-wrap;
}

.notification-wrapper-box p:nth-child(2) {
    font-size: 12px !important;
    color: var(--text-color);
}

.notification-wrapper-box .delete-but-container {
    width: 60px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    justify-content: center;

    cursor: pointer;
}

.notification-wrapper-box img {
    width: 14px;
    height: 14px;
    mix-blend-mode: multiply;
    margin-top: 22px;
}

.notification-wrapper-box .delete-but-container:hover,
.notification-wrapper-box p:hover {
    opacity: 0.8;
}