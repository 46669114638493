.text-area {
    width: 100%;
    border-radius: 15px;
    background: var(--field-background);
    padding: 21px 39px;
    border: none;
    box-shadow: none;
    outline: none;
    color: var(--light-black);
    font-family: var(--Inter-SemiBold600);
    font-size: 16px;
    line-height: 26px
}

.text-area:focus {
    outline: 1px solid var(--purple)
}