.body-main-container{
    height: calc(100vh - 205px);
    overflow: auto;
}
.questionContainer div:first-child{
    width: 200px;
    display: flex;
    justify-content: center;
}
.customHeight .modal-dialog.modal-lg.modal-dialog-centered .modal-content{
    height: calc(100vh - 60px);
    overflow: auto;
}
.headerStyle{
    border-radius: 6px;
    background: var(--field-background);
    padding: 13px 0px;
}
.questionContainer div:nth-child(2){
    width: calc(100vw - 280px);
}
.questionContainer div:nth-child(3){
    width: 80px
}
.editButton{
    width: fit-content !important;
}
.questionContainer{
    display: flex;
    margin-bottom: 20px;
    justify-content: center;

}